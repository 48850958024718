import React, { useState } from "react";
import KeywordFinder from "./components/KeywordFinder";
import ContentGenerator from "./components/ContentGenerator";

const App = () => {
  const [keywords, setKeywords] = useState("");
  const [imageUrl, setImageUrl] = useState("");

  return (
    <div style={{ padding: 0, margin: 0 }}>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" fill="#000000"><path d="M0 0v100S0 4 500 4s500 96 500 96V0H0Z"></path></svg>
      <div className={`app-container ${imageUrl ? "generated" : ""}`}>
        <div className="content-container">
          <h1 className="title">Keyword and Image Generation System for SEO</h1>
          <KeywordFinder setKeywords={setKeywords} />
          {keywords && (
            <div className="keyword-container">
              {keywords.split(", ").map((keyword, index) => (
                <div key={index} className="keyword-box">
                  {keyword.trim()}
                </div>
              ))}
            </div>
          )}
          <ContentGenerator keywords={keywords} setImageUrl={setImageUrl} />
        </div>
        {imageUrl && (
          <div className="generated-image-container">
            <img
              src={imageUrl}
              alt="Generated Visual"
              className="generated-image"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default App;
