import React, { useState, useEffect } from "react";
import axios from "axios";

const ContentGenerator = ({ keywords, setImageUrl }) => {
  const [loading, setLoading] = useState(false);
  const [imageBlob, setImageBlob] = useState(null);

  useEffect(() => {
    if (keywords) {
      handleGenerateImage();
    }
  }, [keywords]);

  const handleGenerateImage = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        "https://api-inference.huggingface.co/models/stabilityai/stable-diffusion-xl-base-1.0",
        {
          inputs: keywords,
          parameters: {
            seed: Math.floor(Math.random() * 1000),
          },
        },
        {
          headers: {
            Authorization: `Bearer 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`,
          },
          responseType: "blob",
        }
      );

      const imageBlob = new Blob([response.data], { type: "image/jpeg" });
      const imageObjectURL = URL.createObjectURL(imageBlob);
      setImageBlob(imageBlob); 
      setImageUrl(imageObjectURL); 
    } catch (error) {
      console.error(
        "Error generating image:",
        error.response ? error.response.data : error.message
      );
    }
    setLoading(false);
  };

  const handleDownloadImage = () => {
    const link = document.createElement("a");
    link.href = URL.createObjectURL(imageBlob);
    link.download = "generated_image.jpg";
    link.click();
  };

  return (
    <div className="content-generator">
      {loading ? (
        <>
          <div className="generate-info">
            <svg
              width={20}
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 200 200"
              style={{ marginRight: "8px" }}
            >
              <radialGradient
                id="a9"
                cx=".66"
                fx=".66"
                cy=".3125"
                fy=".3125"
                gradientTransform="scale(1.5)"
              >
                <stop offset="0" stopColor="#000000"></stop>
                <stop offset=".3" stopColor="#000000" stopOpacity=".9"></stop>
                <stop offset=".6" stopColor="#000000" stopOpacity=".6"></stop>
                <stop offset=".8" stopColor="#000000" stopOpacity=".3"></stop>
                <stop offset="1" stopColor="#000000" stopOpacity="0"></stop>
              </radialGradient>
              <circle
                transform-origin="center"
                fill="none"
                stroke="url(#a9)"
                strokeWidth="15"
                strokeLinecap="round"
                strokeDasharray="200 1000"
                strokeDashoffset="0"
                cx="100"
                cy="100"
                r="70"
              >
                <animateTransform
                  type="rotate"
                  attributeName="transform"
                  calcMode="spline"
                  dur="2s"
                  values="360;0"
                  keyTimes="0;1"
                  keySplines="0 0 1 1"
                  repeatCount="indefinite"
                ></animateTransform>
              </circle>
              <circle
                transform-origin="center"
                fill="none"
                opacity=".2"
                stroke="#000000"
                strokeWidth="15"
                strokeLinecap="round"
                cx="100"
                cy="100"
                r="70"
              ></circle>
            </svg>
            <span>Generating image...</span>
          </div>
        </>
      ) : (
        keywords && (
          <div
            style={{ display: "flex", gap: "10px", justifyContent: "center" }}
          >
            <button
              className="regenerate styled-button"
              onClick={handleGenerateImage}
            >
              <svg
                fill="#ffffff"
                height="20px"
                width="20px"
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                viewBox="0 0 250.491 250.491"
                xmlSpace="preserve"
                stroke="#ffffff"
                style={{ marginRight: "8px" }}
              >
                <g>
                  <path d="M245.491,85.341c-2.761,0-5,2.238-5,5v3c0,2.762,2.239,5,5,5s5-2.238,5-5v-3C250.491,87.579,248.252,85.341,245.491,85.341 z"></path>
                  <path d="M225.571,0.001h-103.49c-13.741,0-24.92,11.179-24.92,24.92v72.243H24.917C11.178,97.164,0,108.342,0,122.082v16.259 c0,2.762,2.239,5,5,5c2.761,0,5-2.238,5-5v-16.259c0-8.226,6.692-14.918,14.917-14.918h103.491c8.225,0,14.917,6.692,14.917,14.918 v103.491c0,8.226-6.692,14.917-14.917,14.917H24.917c-8.226,0-14.917-6.691-14.917-14.917v-64.232c0-2.762-2.239-5-5-5 c-2.761,0-5,2.238-5,5v64.232c0,13.739,11.178,24.917,24.917,24.917h103.491c13.74,0,24.917-11.178,24.917-24.917v-72.242h72.245 c13.741,0,24.92-11.18,24.92-24.921v-19.736c0-2.762-2.239-5-5-5s-5,2.238-5,5v19.736c0,8.228-6.693,14.921-14.92,14.921h-72.245 v-21.249c0-13.74-11.178-24.918-24.917-24.918h-21.248V24.921c0-8.227,6.693-14.92,14.92-14.92h103.49 c8.227,0,14.92,6.693,14.92,14.92v49.753c0,2.762,2.239,5,5,5s5-2.238,5-5V24.921C250.491,11.179,239.311,0.001,225.571,0.001z"></path>
                  <circle cx="41.496" cy="136.527" r="9.749"></circle>
                  <circle cx="41.496" cy="173.577" r="9.749"></circle>
                  <circle cx="41.496" cy="210.627" r="9.749"></circle>
                  <circle cx="111.996" cy="136.527" r="9.749"></circle>
                  <circle cx="111.996" cy="173.577" r="9.749"></circle>
                  <circle cx="111.996" cy="210.627" r="9.749"></circle>
                  <circle cx="173.826" cy="76.665" r="9.749"></circle>
                </g>
              </svg>
              Regenerate
            </button>
            <button className="styled-button" onClick={handleDownloadImage}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="#ffffff"
                height="20px"
                width="20px"
                viewBox="0 0 24 24"
              >
                <path d="M5 20h14v-2H5v2zM19 9h-4V3H9v6H5l7 7 7-7z" />
              </svg>
              Download
            </button>
          </div>
        )
      )}
    </div>
  );
};

export default ContentGenerator;
