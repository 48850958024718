import React, { useState } from 'react';
import axios from 'axios';

const KeywordFinder = ({ setKeywords }) => {
  const [inputText, setInputText] = useState('');
  const [loading, setLoading] = useState(false);

  const handleGenerateKeywords = async () => {
    setLoading(true);
    try {
      const response = await axios.post(
        'https://api-inference.huggingface.co/models/fabiochiu/t5-base-tag-generation',
        {
          inputs: inputText,
        },
        {
          headers: {
            Authorization: `Bearer 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`,
          },
        }
      );
      const keywords = response.data[0].generated_text;
      setKeywords(keywords);
    } catch (error) {
      console.error('Error generating keywords:', error);
    }
    setLoading(false);
  };

  return (
    <div className="centralize">
      <div className="top">
        <textarea
          rows="6"
          cols="500"
          placeholder="Enter text..."
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
        <br />
        <button onClick={handleGenerateKeywords} disabled={loading} className="styled-button">
          {loading ? (
            <>
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><radialGradient id="a9" cx=".66" fx=".66" cy=".3125" fy=".3125" gradientTransform="scale(1.5)"><stop offset="0" stop-color="#000000"></stop><stop offset=".3" stop-color="#000000" stop-opacity=".9"></stop><stop offset=".6" stop-color="#000000" stop-opacity=".6"></stop><stop offset=".8" stop-color="#000000" stop-opacity=".3"></stop><stop offset="1" stop-color="#000000" stop-opacity="0"></stop></radialGradient><circle transform-origin="center" fill="none" stroke="url(#a9)" stroke-width="15" stroke-linecap="round" stroke-dasharray="200 1000" stroke-dashoffset="0" cx="100" cy="100" r="70"><animateTransform type="rotate" attributeName="transform" calcMode="spline" dur="2" values="360;0" keyTimes="0;1" keySplines="0 0 1 1" repeatCount="indefinite"></animateTransform></circle><circle transform-origin="center" fill="none" opacity=".2" stroke="#000000" stroke-width="15" stroke-linecap="round" cx="100" cy="100" r="70"></circle></svg>
              <span className="styled-button-text">Finding...</span>
            </>
          ) : (
            <>
             <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24"><g fill="none" stroke="#FFFFFF" stroke-width="2"><circle cx="12" cy="12" r="8.5"></circle><path d="M1 12h5M18 12h5M12 6V1.04M12 23v-4.96M11.95 11.95h.1v.1h-.1z"></path></g></svg>
              <span className="styled-button-text">Find Keywords</span>
            </>
          )}
        </button>
      </div>
    </div>
  );
};

export default KeywordFinder;
